// for pc
@media (min-width: 1020px) {
    div.home-services {
        height: 450px;
        width: 100%;
        @include flex;
        background-color: rgb(244, 244, 244);
        background-size: cover;
        justify-content: flex-start;
        padding: 0 10% 0 10%;

        div.serviceDivRight {
            width: 50%;
            height: 80%;
            // border: 1px solid red;
            padding: 80px 50px;
            background: white;

            span.serviceSmallHeading {
                margin-bottom: 0;
                line-height: 1rem;
                font-size: 0.6875rem;
                letter-spacing: 0.1rem;
                // text-transform: uppercase;
            }
            h3.serviceH3 {
                font-size: 1.5rem;
                letter-spacing: 0.1rem;
                border-bottom: 1px solid grey;
                padding-bottom: 15px;
                margin-bottom: 17px;
                line-height: normal;
                // text-transform: uppercase;
            }
            p.servicePara {
                margin-bottom: 20px;
                line-height: 1.5rem;
                letter-spacing: 0.025rem;
                font-size: 1.2rem;
            }
            a.bookNowAnchor {
                display: inline-flex;
                align-items: center;
                border-bottom: 0.25rem solid $base-purple;
                padding-bottom: 0.3125rem;
                font-size: 1.125rem;
                color: inherit;
                text-transform: none;
                letter-spacing: 0.025rem;
                overflow: visible;
                white-space: normal;
                text-overflow: ellipsis;
                transition: color 0.15s ease-in-out;
                text-decoration: none;
                transition: 0.3s;
                position: relative;
                transition: 1s;
                cursor: pointer;

                &::after {
                    content: "";
                    border: 2px solid $base-purple;
                    position: absolute;
                    width: 0px;
                    bottom: -4px;
                    transition: 1s;
                    background: $base-purple;
                }

                .arrowBookNow {
                    color: $base-purple;
                    font-weight: bold;
                    transition: 0.2s;
                }

                &:hover {
                    &::after {
                        // content: "";
                        border: 2px solid $base-orange;
                        background: $base-orange;
                        // position: absolute;
                        width: 100%;
                        // bottom: -4px;
                    }
                    .arrowBookNow {
                        color: $base-orange;
                        font-weight: bold;
                    }
                }
            }
        }
    }
    div.inverted-h-s {
        background: white;
        background-size: cover;
        justify-content: flex-end;
    }
}
// for tab
@media (min-width: 760px) and (max-width: 1020px) {
    div.home-services {
        height: 450px;
        width: 100%;
        @include flex;
        background-color: rgb(244, 244, 244);
        background-size: cover;
        // justify-content: flex-start;
        padding: 0 10% 0 10%;

        div.serviceDivRight {
            width: 90%;
            height: 80%;
            // border: 1px solid red;
            padding: 80px 50px;
            background: white;

            span.serviceSmallHeading {
                margin-bottom: 0;
                line-height: 1rem;
                font-size: 0.6875rem;
                letter-spacing: 0.1rem;
                // text-transform: uppercase;
            }
            h3.serviceH3 {
                font-size: 1.5rem;
                letter-spacing: 0.1rem;
                border-bottom: 1px solid grey;
                padding-bottom: 15px;
                margin-bottom: 17px;
                line-height: normal;
                // text-transform: uppercase;
            }
            p.servicePara {
                margin-bottom: 20px;
                line-height: 1.5rem;
                letter-spacing: 0.025rem;
                font-size: 1.2rem;
            }
            a.bookNowAnchor {
                display: inline-flex;
                align-items: center;
                border-bottom: 0.25rem solid $base-purple;
                padding-bottom: 0.3125rem;
                font-size: 1.125rem;
                color: inherit;
                text-transform: none;
                letter-spacing: 0.025rem;
                overflow: visible;
                white-space: normal;
                text-overflow: ellipsis;
                transition: color 0.15s ease-in-out;
                text-decoration: none;
                transition: 0.3s;
                position: relative;
                transition: 1s;
                cursor: pointer;

                &::after {
                    content: "";
                    border: 2px solid $base-purple;
                    position: absolute;
                    width: 0px;
                    bottom: -4px;
                    transition: 1s;
                    background: $base-purple;
                }

                .arrowBookNow {
                    color: $base-purple;
                    font-weight: bold;
                    transition: 0.2s;
                }

                &:hover {
                    &::after {
                        // content: "";
                        border: 2px solid $base-orange;
                        background: $base-orange;
                        // position: absolute;
                        width: 100%;
                        // bottom: -4px;
                    }
                    .arrowBookNow {
                        color: $base-orange;
                        font-weight: bold;
                    }
                }
            }
        }
    }
    div.inverted-h-s {
        background: white;
        background-size: cover;
        // justify-content: flex-end;
    }
}
// for mob
@media (max-width: 760px) {
    div.home-services {
        height: 450px;
        width: 100%;
        @include flex;
        background-color: rgb(244, 244, 244);
        background-size: cover;
        // justify-content: flex-start;
        padding: 0 5% 0 5%;

        div.serviceDivRight {
            width: 100%;
            height: 80%;
            // border: 1px solid red;
            padding: 20px 20px;
            background: white;

            span.serviceSmallHeading {
                margin-bottom: 0;
                line-height: 1rem;
                font-size: 0.6875rem;
                letter-spacing: 0.1rem;
                // text-transform: uppercase;
            }
            h3.serviceH3 {
                font-size: 1.5rem;
                letter-spacing: 0.1rem;
                border-bottom: 1px solid grey;
                padding-bottom: 15px;
                margin-bottom: 17px;
                line-height: normal;
                // text-transform: uppercase;
            }
            p.servicePara {
                margin-bottom: 20px;
                line-height: 1.5rem;
                letter-spacing: 0.025rem;
                font-size: 1.2rem;
            }
            a.bookNowAnchor {
                display: inline-flex;
                align-items: center;
                border-bottom: 0.25rem solid $base-purple;
                padding-bottom: 0.3125rem;
                font-size: 1.125rem;
                color: inherit;
                text-transform: none;
                letter-spacing: 0.025rem;
                overflow: visible;
                white-space: normal;
                text-overflow: ellipsis;
                transition: color 0.15s ease-in-out;
                text-decoration: none;
                transition: 0.3s;
                position: relative;
                transition: 1s;
                cursor: pointer;

                &::after {
                    content: "";
                    border: 2px solid $base-purple;
                    position: absolute;
                    width: 0px;
                    bottom: -4px;
                    transition: 1s;
                    background: $base-purple;
                }

                .arrowBookNow {
                    color: $base-purple;
                    font-weight: bold;
                    transition: 0.2s;
                }

                &:hover {
                    &::after {
                        // content: "";
                        border: 2px solid $base-orange;
                        background: $base-orange;
                        // position: absolute;
                        width: 100%;
                        // bottom: -4px;
                    }
                    .arrowBookNow {
                        color: $base-orange;
                        font-weight: bold;
                    }
                }
            }
        }
    }
    div.inverted-h-s {
        background: white;
        background-size: cover;
        // justify-content: flex-end;
    }
}
