// // for pc
// @media(min-width:1020px){

// }
// // for tab
// @media(min-width:760px) and (max-width:1020px){

// }
// // for mob
// @media(max-width:760px){

// }

div.Floater {
    position: fixed;
    bottom: 0;
    height: 50px;
    width: 100%;
    // background-color: $base-purple;
    @include flex;

    div.TheContainer {
        height: 100%;
        width: 20%;
        min-width: 250px;
        border-top-left-radius: 100px;
        border-top-right-radius: 100px;
        background-color: $base-orange;
        border: 1px solid white;
        @include flex;

        a.footerContactLink2 {
            color: white !important;
            // padding: 0px 0px 2px 2px;
            // width: 180px;
            font-size: 16px;
            font-weight: bolder;

            &:hover {
                border-bottom: 1px solid white;
                text-decoration: none;
            }
        }
        // z-index: 10000;
    }
}

div.FloaterCookies {
    position: fixed;
    bottom: 0;
    min-height: 80px;
    width: 100%;
    background-color: $base-purple;
    padding: 0 10%;
    color: white;
    font-size: 14px;
    display: flex;
    // @include flex;
}

// for pc
@media (min-width: 1020px) {
    div.FloaterCookies {
        position: fixed;
        bottom: 0;
        height: 80px;
        width: 100%;
        background-color: $base-purple;
        padding: 0 10%;
        color: white;
        font-size: 14px;
        display: flex;
        // @include flex;

        div.PDIV {
            height: 100%;
            width: 80%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        div.ButtonDiv {
            height: 100%;
            width: 20%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
    }
}
// for tab
@media (min-width: 760px) and (max-width: 1020px) {
    div.FloaterCookies {
        position: fixed;
        bottom: 0;
        height: 180px;
        width: 100%;
        background-color: $base-purple;
        padding: 0 10%;
        color: white;
        font-size: 14px;
        display: flex;
        flex-direction: column;
        // @include flex;

        div.PDIV {
            height: 60%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            justify-content: center;
            align-items: center;
        }
        div.ButtonDiv {
            height: 40%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            justify-content: center;
            align-items: center;
        }
    }
}
// for mob
@media(max-width:760px){
    div.FloaterCookies {
        position: fixed;
        bottom: 0;
        height: 200px;
        width: 100%;
        background-color: $base-purple;
        padding: 0 10%;
        color: white;
        font-size: 14px;
        display: flex;
        flex-direction: column;
        // @include flex;

        div.PDIV {
            height: 60%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            justify-content: center;
            align-items: center;
        }
        div.ButtonDiv {
            height: 40%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            justify-content: center;
            align-items: center;
        }
    }
}
