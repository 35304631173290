// for pc
@media (min-width: 1020px) {
    section.serviceSection {
        height: 467px;
        width: 100%;
        @include flex;

        div.serviceDivContainer {
            height: 100%;
            width: 80%;
            @include flex;

            div.serviceDivLeft {
                width: 50%;
                height: 100%;
                clip-path: polygon(0 0, 52% 0, 100% 100%, 0% 100%);
            }
            div.serviceDivRight {
                width: 50%;
                min-height: 100%;
                // border: 1px solid red;
                padding: 80px 22px;

                span.serviceSmallHeading {
                    margin-bottom: 0;
                    line-height: 1rem;
                    font-size: 0.6875rem;
                    letter-spacing: 0.1rem;
                    // text-transform: uppercase;
                }
                h3.serviceH3 {
                    font-size: 1.3rem;
                    letter-spacing: 0.1rem;
                    border-bottom: 1px solid grey;
                    padding-bottom: 15px;
                    margin-bottom: 17px;
                    line-height: normal;
                    // text-transform: uppercase;
                }
                p.servicePara {
                    margin-bottom: 20px;
                    line-height: 1.5rem;
                    letter-spacing: 0.025rem;
                    font-size: 1rem;
                }
                a.bookNowAnchor {
                    display: inline-flex;
                    align-items: center;
                    border-bottom: 0.25rem solid $base-purple;
                    padding-bottom: 0.3125rem;
                    font-size: 1.125rem;
                    color: inherit;
                    text-transform: none;
                    letter-spacing: 0.025rem;
                    overflow: visible;
                    white-space: normal;
                    text-overflow: ellipsis;
                    transition: color 0.15s ease-in-out;
                    text-decoration: none;
                    transition: 0.3s;
                    position: relative;
                    transition: 1s;
                    cursor: pointer;

                    &::after {
                        content: "";
                        border: 2px solid $base-purple;
                        position: absolute;
                        width: 0px;
                        bottom: -4px;
                        transition: 1s;
                        background: $base-purple;
                    }

                    .arrowBookNow {
                        color: $base-purple;
                        font-weight: bold;
                        transition: 0.2s;
                    }

                    &:hover {
                        &::after {
                            // content: "";
                            border: 2px solid $base-orange;
                            background: $base-orange;
                            // position: absolute;
                            width: 100%;
                            // bottom: -4px;
                        }
                        .arrowBookNow {
                            color: $base-orange;
                            font-weight: bold;
                        }
                    }
                }
            }
        }
    }
    section.invert {
        background-color: rgb(244, 244, 244);

        div.serviceDivContainer {
            flex-direction: row-reverse;

            div.serviceDivLeft {
                width: 50%;
                height: 100%;
                clip-path: polygon(52% 0, 100% 0, 100% 100%, 0% 100%);
            }
        }
    }
}
// for tab
@media (min-width: 760px) and (max-width: 1020px) {
    section.serviceSection {
        // height: 450px;
        width: 100%;
        @include flex;

        div.serviceDivContainer {
            height: 900px;
            width: 100%;
            @include flex;
            flex-direction: column;

            div.serviceDivLeft {
                width: 100%;
                height: 100%;
            }
            div.serviceDivRight {
                width: 80%;
                height: 100%;
                // border: 1px solid red;
                padding: 80px 50px;

                span.serviceSmallHeading {
                    margin-bottom: 0;
                    line-height: 1rem;
                    font-size: 0.6875rem;
                    letter-spacing: 0.1rem;
                    // text-transform: uppercase;
                }
                h3.serviceH3 {
                    font-size: 1.3rem;
                    letter-spacing: 0.1rem;
                    border-bottom: 1px solid grey;
                    padding-bottom: 15px;
                    margin-bottom: 17px;
                    line-height: normal;
                    // text-transform: uppercase;
                }
                p.servicePara {
                    margin-bottom: 20px;
                    line-height: 1.5rem;
                    letter-spacing: 0.025rem;
                    font-size: 1.1rem;
                }
                a.bookNowAnchor {
                    display: inline-flex;
                    align-items: center;
                    border-bottom: 0.25rem solid $base-purple;
                    padding-bottom: 0.3125rem;
                    font-size: 1.125rem;
                    color: inherit;
                    text-transform: none;
                    letter-spacing: 0.025rem;
                    overflow: visible;
                    white-space: normal;
                    text-overflow: ellipsis;
                    transition: color 0.15s ease-in-out;
                    text-decoration: none;
                    transition: 0.3s;
                    position: relative;
                    transition: 1s;

                    &::after {
                        content: "";
                        border: 2px solid $base-purple;
                        position: absolute;
                        width: 0px;
                        bottom: -4px;
                        transition: 1s;
                        background: $base-purple;
                    }

                    .arrowBookNow {
                        color: $base-purple;
                        font-weight: bold;
                        transition: 0.2s;
                    }

                    &:hover {
                        &::after {
                            // content: "";
                            border: 2px solid $base-orange;
                            background: $base-orange;
                            // position: absolute;
                            width: 100%;
                            // bottom: -4px;
                        }
                        .arrowBookNow {
                            color: $base-orange;
                            font-weight: bold;
                        }
                    }
                }
            }
        }
    }
    section.invert {
        background-color: rgb(244, 244, 244);
    }
}
// for mob
@media (max-width: 760px) {
    section.serviceSection {
        // height: 450px;
        width: 100%;
        @include flex;

        div.serviceDivContainer {
            height: 900px;
            width: 100%;
            @include flex;
            flex-direction: column;

            div.serviceDivLeft {
                width: 100%;
                height: 100%;
            }
            div.serviceDivRight {
                width: 90%;
                height: 100%;
                // border: 1px solid red;
                padding: 80px 10px;

                span.serviceSmallHeading {
                    margin-bottom: 0;
                    line-height: 1rem;
                    font-size: 0.6875rem;
                    letter-spacing: 0.1rem;
                    // text-transform: uppercase;
                }
                h3.serviceH3 {
                    font-size: 1.3rem;
                    letter-spacing: 0.1rem;
                    border-bottom: 1px solid grey;
                    padding-bottom: 15px;
                    margin-bottom: 17px;
                    line-height: normal;
                    // text-transform: uppercase;
                }
                p.servicePara {
                    margin-bottom: 20px;
                    line-height: 1.5rem;
                    letter-spacing: 0.025rem;
                    font-size: 1.1rem;
                }
                a.bookNowAnchor {
                    display: inline-flex;
                    align-items: center;
                    border-bottom: 0.25rem solid $base-purple;
                    padding-bottom: 0.3125rem;
                    font-size: 1.125rem;
                    color: inherit;
                    text-transform: none;
                    letter-spacing: 0.025rem;
                    overflow: visible;
                    white-space: normal;
                    text-overflow: ellipsis;
                    transition: color 0.15s ease-in-out;
                    text-decoration: none;
                    transition: 0.3s;
                    position: relative;
                    transition: 1s;

                    &::after {
                        content: "";
                        border: 2px solid $base-purple;
                        position: absolute;
                        width: 0px;
                        bottom: -4px;
                        transition: 1s;
                        background: $base-purple;
                    }

                    .arrowBookNow {
                        color: $base-purple;
                        font-weight: bold;
                        transition: 0.2s;
                    }

                    &:hover {
                        &::after {
                            // content: "";
                            border: 2px solid $base-orange;
                            background: $base-orange;
                            // position: absolute;
                            width: 100%;
                            // bottom: -4px;
                        }
                        .arrowBookNow {
                            color: $base-orange;
                            font-weight: bold;
                        }
                    }
                }
            }
        }
    }
    section.invert {
        background-color: rgb(244, 244, 244);
    }
}
