// for pc
@media(min-width:1020px){
    div.tracking-list {
        height: 250px;
        width: 100%;
        background-image: url(../../../assets//images/tracking_bg.jpg);
        @include flex;
        margin-top: 40px;
    
        div.ULContainer {
            height: 85%;
            width: 90%;
            background: white;
            @include flex;
            position: relative;
    
            hr.myHr{
                width: 100%;
                position: absolute;
                border-top: 1px dotted $base-orange;
                margin-top: -35px;
            }
    
            ul {
                display: flex;
                width: 100%;
                justify-content: space-around;
    
                li {
                    z-index: 100;
                    height: 100px;
                    width: 100px;
                    @include flex;
                    flex-direction: column;
                    justify-content: space-between;
    
                    div.tracking-list-icon {
                        height: 60px;
                        width: 60px;
                        border: 1px dotted $base-orange;
                        border-radius: 100%;
                        background-color: rgb(228, 228, 228);
                        @include flex;
                        color: grey;
                        // font-size: 100px;
                    }
    
                    div.tracking-list-content{
                        p{
                            margin: 0;
                            padding: 0;
    
                        }
                    }
                }
                li.active{
                    div.tracking-list-icon{
                        background: $base-orange;
                        color: white;
                    }
                    div.tracking-list-content{
                        p{
                            color: $base-orange;
                        }
                    }
                }
            }
        }
    }
    
}
// for tab
@media(min-width:760px) and (max-width:1020px){
    div.tracking-list {
        height: 250px;
        width: 100%;
        background-image: url(../../../assets//images/tracking_bg.jpg);
        @include flex;
        margin-top: 40px;
    
        div.ULContainer {
            height: 85%;
            width: 90%;
            background: white;
            @include flex;
            position: relative;
    
            hr.myHr{
                width: 100%;
                position: absolute;
                border-top: 1px dotted $base-orange;
                margin-top: -35px;
            }
    
            ul {
                display: flex;
                width: 100%;
                justify-content: space-around;
    
                li {
                    z-index: 100;
                    height: 100px;
                    width: 100px;
                    @include flex;
                    flex-direction: column;
                    justify-content: space-between;
    
                    div.tracking-list-icon {
                        height: 60px;
                        width: 60px;
                        border: 1px dotted $base-orange;
                        border-radius: 100%;
                        background-color: rgb(228, 228, 228);
                        @include flex;
                        color: grey;
                        // font-size: 100px;
                    }
    
                    div.tracking-list-content{
                        p{
                            margin: 0;
                            padding: 0;
    
                        }
                    }
                }
                li.active{
                    div.tracking-list-icon{
                        background: $base-orange;
                        color: white;
                    }
                    div.tracking-list-content{
                        p{
                            color: $base-orange;
                        }
                    }
                }
            }
        }
    }
    
}
// for mob
@media(max-width:760px){
    div.tracking-list {
        height: 250px;
        width: 100%;
        background-image: url(../../../assets//images/tracking_bg.jpg);
        @include flex;
        margin-top: 40px;
    
        div.ULContainer {
            height: 85%;
            width: 95%;
            background: white;
            @include flex;
            position: relative;
    
            hr.myHr{
                width: 100%;
                position: absolute;
                border-top: 1px dotted $base-orange;
                margin-top: -45px;
            }
    
            ul {
                display: flex;
                width: 100%;
                justify-content: space-around;
    
                li {
                    z-index: 100;
                    height: 100px;
                    width: 100px;
                    @include flex;
                    flex-direction: column;
                    justify-content: space-between;
    
                    div.tracking-list-icon {
                        height: 50px;
                        width: 50px;
                        border: 1px dotted $base-orange;
                        border-radius: 100%;
                        background-color: rgb(228, 228, 228);
                        @include flex;
                        color: grey;
                        // font-size: 100px;
                    }
    
                    div.tracking-list-content{
                        p{
                            margin: 0;
                            padding: 0;
                            font-size: 13px;
                        }
                    }
                }
                li.active{
                    div.tracking-list-icon{
                        background: $base-orange;
                        color: white;
                    }
                    div.tracking-list-content{
                        p{
                            color: $base-orange;
                        }
                    }
                }
            }
        }
    }
    
}

