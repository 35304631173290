div.transport-steps{
    // background-image: url(../../../assets/images/IHD_Desktop.jpg);
    background-repeat: no-repeat;
    background-size:cover;
    // background-position: -500px 0px;
    height: auto;
    min-height: 500px;
    letter-spacing: 1px;
    padding: 20px 20px;
    display: flex;
    align-items: center;

    p.e-commerce{
        font-size: 11px;
        color: rgb(42, 41, 41);
        padding: 0px 20px;
    }
    h4.stepsHeading{
        padding: 0px 20px;
        margin-top: -15px;
    } 
    hr.steps-hr{
        background-color: #e5e5e5;
    }
    p.section-content{
        padding: 0px 20px;
        line-height: 1.4;
        font-weight: 500;
        font-size: 16px;
    }
    div.counter-steps{
        padding: 0px 20px;
        font-size: 14px;
        font-weight: 600;

        &::before{
            font-size: 12px;
            font-weight: bold;
            display: inline-flex;
            border: 1px solid #262626;
            width: 20px;
            height: 20px;
            border-radius: 150%;
            line-height: 1.5;
            margin: 10px;
            align-items: center;
            justify-content: center;
            content: counter(section);
            counter-increment: section;
        }
    }
    a.ctaLink {
        display: inline-flex;
        align-items: center;
        border-bottom: .25rem solid #480f8f;
        margin: 0px 20px 35px 20px;
        font-size: 1.125rem;
        color: inherit;
        text-transform: none;
        letter-spacing: .025rem;
        overflow: visible;
        white-space: normal;
    }
    img.etsy{
        height: 40px;
        width: 70px;
        padding: 10px 10px;
    }
    p.homepage-p{
        font-size: 16px;
        line-height: 26px;
        color: #000;
        font-family: 'Open Sans', sans-serif;
    }
    a.ihdSection-etsyCta {
        text-decoration: underline;
        font-weight: 700;
        color: #02aaf3;
        &:hover{
            color: #0056b3;
            text-decoration: underline;
            cursor: pointer;
        }
    }

}

// // for pc
// @media(min-width:1020px){

// }
// // for tab
// @media(min-width:760px) and (max-width:1020px){

// }
// // for mob
// @media(max-width:760px){

// }