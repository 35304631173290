.footerContactLink {
    color: white !important;
    padding: 0px 0px 2px 2px;
    width: 80px;
    font-size: 16px;

    &:hover {
        border-bottom: 1px solid white;
        text-decoration: none;
    }
}
