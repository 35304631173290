// for pc
@media (min-width: 1020px) {
    section.transportation {
        width: 100%;
        height: auto;
        @include flex;
        flex-wrap: wrap;
        padding: 0 10%;

        div.transBox {
            cursor: pointer;
            width: 25%;
            height: 200px;
            // height: 100%;
            transition: 0.1s;
            // border: 1px solid red;
            @include flex;
            flex-direction: column;

            img.transImg {
                height: 100px;
            }
            span.transSpan {
                font-size: 20px;
                font-weight: bold;
                color: $base-purple;

                &:hover {
                    color: $base-orange;
                }
            }
            span.transSpan2 {
                text-align: center;
            }

            &:hover {
                border-bottom: 8px solid $base-purple;
            }
        }
    }
}
// for tab
@media (min-width: 760px) and (max-width: 1020px) {
    section.transportation {
        height: auto;
        width: 100%;
        @include flex;
        flex-wrap: wrap;

        div.transBox {
            width: 50%;
            height: 200px;
            // border: 1px solid red;
            @include flex;
            flex-direction: column;

            img.transImg {
                height: 100px;
            }
            span.transSpan {
                font-size: 20px;
                font-weight: bold;
                color: $base-purple;
            }
            span.transSpan2 {
                text-align: center;
            }
        }
    }
}
// for mob
@media (max-width: 760px) {
    section.transportation {
        // height: 360px;
        width: 100%;
        @include flex;
        flex-wrap: wrap;

        div.transBox {
            height: 180px;
            width: 100%;
            padding: 7.5%;
            // height: 50%;
            // border: 1px solid red;
            @include flex;
            flex-direction: column;

            img.transImg {
                height: 100px;
            }
            span.transSpan {
                font-size: 20px;
                font-weight: bold;
                color: $base-purple;
            }
            span.transSpan2 {
                text-align: center;
            }
        }
    }
}
