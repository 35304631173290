// // for pc
// @media(min-width:1020px){

// }
// // for tab
// @media(min-width:760px) and (max-width:1020px){

// }
// // for mob
// @media(max-width:760px){

// }

section.banner {
    height: 236px;
    width: 100%;
    // background-image: url(../../../assets/images/bread-bg.jpg);
    background-size: cover;
    // background-position: center -550px;
    background-attachment: fixed;
    position: relative;

    div.overLay {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        // background-color: #092c3e7a;
        @include flex;

        div.bannerBox {
            width: 80%;
            height: 150px;
            // border: 1px solid red;
            h1.bannerMainHeading {
                font-size: 38px;
                font-weight: 700;
                letter-spacing: 1px;
                color: #fff;
                margin-top: 40px;
                position: relative;
                padding-left: 30px;

                &::before {
                    position: absolute;
                    content: " ";
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    border-left: 5px solid #fe6301;
                }
            }

            h6.breadCrum{
                color: grey;

                span.home{
                    color: white;

                    &:hover{
                        text-decoration: underline;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}
