nav.myNavBar {
    // border-bottom: .5px solid #ddd;
    width: 100%;
    background: $base-purple;
    z-index: 100000000;
    // position: fixed;
    padding: 0 10%;
    a.NavLinksForNavBar {
        padding: 15px;
        text-decoration: none;
        color: white;
        font-weight: bold;
        transition: 0.5s;
        font-size: 13.5px;

        &:hover {
            color: $base-orange;
        }
    }
}

// for pc
@media (min-width: 1024px) {
    button.loginButton {
        border: none;
        outline: none;
        height: 36px;
        width: 83px;
        border-radius: 30px;
        font-size: 14px;
        margin-right: 10px;
        font-weight: bold;
        background: transparent;
        color: white;

        img {
            margin-right: 10px;
        }
        &:hover {
            background: rgba(255, 255, 255, 0.418);
            transition: 0.5s;
            // color: $base-purple;
        }
    }

    button.signUpButton {
        border: none;
        outline: none;
        height: 36px;
        width: 73px;
        // padding: 10px 20px;
        border-radius: 30px;
        font-size: 14px;
        background: white;
        font-weight: bold;
        color: $base-purple;

        &:hover {
            filter: brightness(80%);
            transition: 0.5s;
        }
    }
    button.DashboardButton {
        border: none;
        outline: none;
        height: 36px;
        width: 133px;
        // padding: 10px 20px;
        border-radius: 30px;
        font-size: 14px;
        background: white;
        font-weight: bold;
        color: $base-purple;

        &:hover {
            filter: brightness(80%);
            transition: 0.5s;
        }
    }

    nav.NewNavBar {
        height: 64px;
        width: 100%;
        background: $base-purple;
        z-index: 100000000;
        // position: fixed;
        padding: 0 10%;
        display: flex;
        align-items: center;
        img {
            height: 40px;
        }

        div.navLeft {
            flex: 2.5;
            // border: 1px solid red;
            @include flex;
            justify-content: center;
            img {
                height: 40px;
            }
            a.NavLinksForNavBar {
                padding: 15px;
                text-decoration: none;
                color: white;
                font-weight: bold;
                transition: 0.5s;
                font-size: 13.5px;

                &:hover {
                    color: $base-orange;
                }
            }
        }
        div.navRight {
            height: 100%;
            width: auto;
            // border: 1px solid red;
            @include flex;
            justify-content: flex-end;

            button.loginButton {
                border: none;
                outline: none;
                height: 36px;
                width: 83px;
                border-radius: 30px;
                font-size: 14px;
                margin-right: 10px;
                font-weight: bold;
                background: transparent;
                color: white;

                img {
                    margin-right: 10px;
                }
                &:hover {
                    background: rgba(255, 255, 255, 0.418);
                    transition: 0.5s;
                    // color: $base-purple;
                }
            }

            button.signUpButton {
                border: none;
                outline: none;
                height: 36px;
                width: 73px;
                // padding: 10px 20px;
                border-radius: 30px;
                font-size: 14px;
                background: white;
                font-weight: bold;
                color: $base-purple;

                &:hover {
                    filter: brightness(80%);
                    transition: 0.5s;
                }
            }
            button.DashboardButton {
                border: none;
                outline: none;
                height: 36px;
                width: 133px;
                // padding: 10px 20px;
                border-radius: 30px;
                font-size: 14px;
                background: white;
                font-weight: bold;
                color: $base-purple;

                &:hover {
                    filter: brightness(80%);
                    transition: 0.5s;
                }
            }
        }
    }
}
// for tab
@media (min-width: 760px) and (max-width: 1024px) {
    button.loginButton {
        border: none;
        outline: none;
        height: 36px;
        width: 43px;
        border-radius: 30px;
        font-size: 14px;
        margin-right: 10px;
        font-weight: bold;
        background: transparent;
        color: white;
        &:hover {
            background: rgba(255, 255, 255, 0.418);
            transition: 0.5s;
            color: $base-purple;
        }
    }

    button.signUpButton {
        border: none;
        outline: none;
        height: 36px;
        width: 73px;
        // padding: 10px 20px;
        border-radius: 30px;
        font-size: 14px;
        background: white;
        font-weight: bold;
        color: $base-purple;

        &:hover {
            filter: brightness(80%);
            transition: 0.5s;
        }
    }
    button.DashboardButton {
        border: none;
        outline: none;
        height: 36px;
        width: 133px;
        // padding: 10px 20px;
        border-radius: 30px;
        font-size: 14px;
        background: white;
        font-weight: bold;
        color: $base-purple;

        &:hover {
            filter: brightness(80%);
            transition: 0.5s;
        }
    }

    
    nav.NewNavBar {
        height: 64px;
        width: 100%;
        background: $base-purple;
        z-index: 100000000;
        position: relative;
        padding: 0 10%;
        display: flex;
        align-items: center;

        img {
            height: 40px;
        }
        div.navLeft {
            flex: 2.5;
            // border: 1px solid red;
            @include flex;
            justify-content: flex-start;
            img {
                height: 40px;
            }
            a.NavLinksForNavBar {
                padding: 15px;
                text-decoration: none;
                color: white;
                font-weight: bold;
                transition: 0.5s;
                font-size: 13.5px;

                &:hover {
                    color: $base-orange;
                }
            }
        }
        div.navRight {
            flex: 1;
            // border: 1px solid red;
            @include flex;
            justify-content: flex-end;

            button.loginButton {
                border: none;
                outline: none;
                height: 36px;
                width: 43px;
                border-radius: 30px;
                font-size: 14px;
                margin-right: 10px;
                font-weight: bold;
                background: transparent;
                color: white;
                &:hover {
                    background: rgba(255, 255, 255, 0.418);
                    transition: 0.5s;
                    color: $base-purple;
                }
            }

            button.signUpButton {
                border: none;
                outline: none;
                height: 36px;
                width: 73px;
                // padding: 10px 20px;
                border-radius: 30px;
                font-size: 14px;
                background: white;
                font-weight: bold;
                color: $base-purple;

                &:hover {
                    filter: brightness(80%);
                    transition: 0.5s;
                }
            }
            button.DashboardButton {
                border: none;
                outline: none;
                height: 36px;
                width: 133px;
                // padding: 10px 20px;
                border-radius: 30px;
                font-size: 14px;
                background: white;
                font-weight: bold;
                color: $base-purple;

                &:hover {
                    filter: brightness(80%);
                    transition: 0.5s;
                }
            }
        }
        div.ResponsiveMenu {
            z-index: 1000;
            position: absolute;
            overflow: hidden;
            padding: 0 10%;
            width: 100%;
            left: 0;
            top: 64px;
            // height: calc(100vh - 64px);
            background: $base-purple;
            display: flex;
            flex-direction: column;
            transition: 0.5s;
            a {
                flex: 1;
                @include flex;
                width: 100%;
                font-size: 30px;
                color: white;
                text-align: center;
            }
        }
    }
}
// for mob
@media (max-width: 760px) {
    button.loginButton {
        border: none;
        outline: none;
        height: 36px;
        width: 30px;
        border-radius: 30px;
        font-size: 14px;
        margin-right: 10px;
        font-weight: bold;
        background: transparent;
        color: white;
        &:hover {
            background: rgba(255, 255, 255, 0.418);
            transition: 0.5s;
            color: $base-purple;
        }
    }

    button.signUpButton {
        border: none;
        outline: none;
        height: 36px;
        width: 73px;
        // padding: 10px 20px;
        border-radius: 30px;
        font-size: 14px;
        background: white;
        font-weight: bold;
        color: $base-purple;

        &:hover {
            filter: brightness(80%);
            transition: 0.5s;
        }
    }
    button.DashboardButton {
        border: none;
        outline: none;
        height: 36px;
        width: 133px;
        // padding: 10px 20px;
        border-radius: 30px;
        font-size: 14px;
        background: white;
        font-weight: bold;
        color: $base-purple;

        &:hover {
            filter: brightness(80%);
            transition: 0.5s;
        }
    }

    nav.NewNavBar {
        height: 64px;
        width: 100%;
        background: $base-purple;
        z-index: 100000000;
        // position: fixed;
        padding: 0 3%;
        display: flex;
        align-items: center;
        img {
            height: 40px;
        }

        div.navLeft {
            flex: 2.5;
            // border: 1px solid red;
            @include flex;
            justify-content: flex-start;
            img {
                height: 40px;
            }
            a.NavLinksForNavBar {
                padding: 15px;
                text-decoration: none;
                color: white;
                font-weight: bold;
                transition: 0.5s;
                font-size: 13.5px;

                &:hover {
                    color: $base-orange;
                }
            }
        }
        div.navRight {
            flex: 1;
            // border: 1px solid red;
            @include flex;
            justify-content: flex-end;

            button.loginButton {
                border: none;
                outline: none;
                height: 36px;
                width: 30px;
                border-radius: 30px;
                font-size: 14px;
                margin-right: 10px;
                font-weight: bold;
                background: transparent;
                color: white;
                &:hover {
                    background: rgba(255, 255, 255, 0.418);
                    transition: 0.5s;
                    color: $base-purple;
                }
            }

            button.signUpButton {
                border: none;
                outline: none;
                height: 36px;
                width: 73px;
                // padding: 10px 20px;
                border-radius: 30px;
                font-size: 14px;
                background: white;
                font-weight: bold;
                color: $base-purple;

                &:hover {
                    filter: brightness(80%);
                    transition: 0.5s;
                }
            }
            button.DashboardButton {
                border: none;
                outline: none;
                height: 36px;
                width: 133px;
                // padding: 10px 20px;
                border-radius: 30px;
                font-size: 14px;
                background: white;
                font-weight: bold;
                color: $base-purple;

                &:hover {
                    filter: brightness(80%);
                    transition: 0.5s;
                }
            }
        }
        div.ResponsiveMenu {
            z-index: 1000;
            position: absolute;
            overflow: hidden;
            padding: 0 10%;
            width: 100%;
            left: 0;
            top: 64px;
            // height: calc(100vh - 64px);
            background: $base-purple;
            display: flex;
            flex-direction: column;
            transition: 0.5s;
            a {
                flex: 1;
                @include flex;
                width: 100%;
                font-size: 17px;
                color: white;
                text-align: center;
            }
        }
    }
}
