// for pc
@media (min-width: 1020px) {
    section.headerSection {
        height: calc(100vh - 64px);
        width: 100%;
        position: relative;
        // background-color: rgb(244, 244, 244);

        div.mainHeadingsDiv {
            height: 100%;
            width: 100%;
            @include flex;
            z-index: 2;

            div.headingContainer {
                width: 80%;
                height: 100%;
                @include flex;

                div.headingInside {
                    min-height: 50%;
                    padding:0px 30px;
                    width: 50%;
                    @include flex;
                    background: white;

                    div.headingHere {
                        height: 65%;
                        width: 100%;
                        padding-top: 10px;
                        // border: 1px solid red;
                        @include flex;
                        flex-direction: column;
                        align-items: initial;

                        h1.TAGLINE {
                            overflow-wrap: normal;
                            text-transform: uppercase;
                            letter-spacing: 0.09em;
                            font-size: 35px;
                            line-height: normal;
                            margin-bottom: 1px;
                            font-weight: bold;
                        }

                        h2.TAGLINE2 {
                            overflow-wrap: normal;
                            text-transform: uppercase;
                            letter-spacing: 0.09em;
                            font-size: 35px;
                            line-height: normal;
                            margin-bottom: 5px;
                            font-weight: bold;

                        }
                        p.slogan {
                            padding-top: 12px;
                            // max-width: 75%;
                            margin: 0px 0px 10px;
                            font-size: 1.125rem;
                            line-height: 1.5rem;
                            letter-spacing: 0.025rem;
                        }
                        div.buttonContainer {
                            // border: 1px solid red;
                            height: 85px;
                            padding-right: 10%;
                            @include flex;
                            justify-content: space-between;
                        }
                    }
                }
                div.headingInside2 {
                    height: 100%;
                    width: 50%;
                    overflow: hidden;
                    position: relative;
                    @include flex;
                    img{
                        height: auto;
                        max-width: 70%;
                    }
                    // video.backgroundVideo {
                    //     position: absolute;
                    //     right: 0px;
                    // }
                }
            }
        }
    }
}
// for tab
@media (min-width: 760px) and (max-width: 1020px) {
    section.headerSection {
        height: 100vh;
        width: 100%;
        position: relative;
        // background-color: rgb(244, 244, 244);

        // video.backgroundVideo{
        //     margin-top: 50px;
        //     height: 90%;
        //     width: 100%;
        //     position: absolute;
        // }
        div.mainHeadingsDiv {
            height: 100%;
            width: 100%;
            @include flex;
            z-index: 2;

            div.headingContainer {
                width: 70%;
                height: 100%;
                @include flex;

                div.headingInside {
                    min-height: 50%;
                    width: 100%;
                    @include flex;
                    background-color: white;
                    padding: 30px;

                    div.headingHere {
                        height: 65%;
                        width: 100%;
                        padding-top: 40px;
                        // border: 1px solid red;
                        @include flex;
                        flex-direction: column;
                        align-items: initial;

                        h1.TAGLINE {
                            overflow-wrap: normal;
                            text-transform: uppercase;
                            letter-spacing: 0.09em;
                            font-size: 45px;
                            line-height: normal;
                            margin-bottom: 17px;
                            font-weight: bold;

                        }

                        h2.TAGLINE2 {
                            overflow-wrap: normal;
                            text-transform: uppercase;
                            letter-spacing: 0.09em;
                            font-size: 45px;
                            line-height: normal;
                            margin-bottom: 17px;
                            font-weight: bold;

                        }
                        p.slogan {
                            padding-top: 12px;
                            // max-width: 75%;
                            margin: 0px 0px 48px;
                            font-size: 1.125rem;
                            line-height: 1.5rem;
                            letter-spacing: 0.025rem;
                        }
                        div.buttonContainer {
                            // border: 1px solid red;
                            height: 85px;
                            padding-right: 10%;
                            @include flex;
                            justify-content: space-between;
                        }
                    }
                }
                div.headingInside2 {
                    height: 0;
                    width: 0;
                    overflow: hidden;
                    position: relative;
                    @include flex;
                    video.backgroundVideo {
                        position: absolute;
                        right: 0px;
                    }
                    display: none;
                }
            }
        }
    }
}
// for mob
@media (max-width: 760px) {
    section.headerSection {
        height: 100vh;
        width: 100%;
        position: relative;
        // background-color: rgb(244, 244, 244);

        // video.backgroundVideo{
        //     margin-top: 50px;
        //     height: 90%;
        //     width: 100%;
        //     position: absolute;
        // }
        div.mainHeadingsDiv {
            height: 100%;
            width: 100%;
            @include flex;
            z-index: 2;

            div.headingContainer {
                width: 90%;
                height: 100%;
                @include flex;

                div.headingInside {
                    min-height: 50%;
                    padding: 20px;
                    width: 100%;
                    @include flex;
                    background-color: white;

                    div.headingHere {
                        height: 65%;
                        width: 100%;
                        // padding-top: 40px;
                        // border: 1px solid red;
                        @include flex;
                        flex-direction: column;
                        align-items: initial;

                        h1.TAGLINE {
                            overflow-wrap: normal;
                            text-transform: uppercase;
                            letter-spacing: 0.09em;
                            font-size: 36px;
                            line-height: normal;
                            margin-bottom: 17px;
                            font-weight: bold;

                        }

                        h2.TAGLINE2 {
                            overflow-wrap: normal;
                            text-transform: uppercase;
                            letter-spacing: 0.09em;
                            font-size: 36px;
                            line-height: normal;
                            margin-bottom: 17px;
                            font-weight: bold;

                        }
                        p.slogan {
                            padding-top: 12px;
                            // max-width: 75%;
                            margin: 0px 0px 48px;
                            font-size: 1.125rem;
                            line-height: 1.5rem;
                            letter-spacing: 0.025rem;
                        }
                        div.buttonContainer {
                            // border: 1px solid red;
                            height: 125px;
                            // padding-right: 10%;
                            @include flex;
                            flex-direction: column;
                            justify-content: flex-start;
                            
                            button.myButtonForHeader{
                                width: 90%;
                                margin: 0;
                                margin-bottom: 10px;
                            }
                        }
                    }
                }
                div.headingInside2 {
                    height: 0;
                    width: 0;
                    overflow: hidden;
                    position: relative;
                    @include flex;
                    video.backgroundVideo {
                        position: absolute;
                        right: 0px;
                    }
                    display: none;
                }
            }
        }
    }
}
