div.mentor-price-table {
    margin: 15px 0px;
    padding: 0px 0px 25px 0px;
    background-color: rgba(0,0,0,0);
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    border-color: #4a108f;

    div.mentor-price-table__header {
        background-color: rgba(255,255,255,0);
        padding: 20px 0;

        h3.mentor-price-table__heading {
            color: #000;
            font-size: 36px;
            margin: 0;
            padding: 0;
            line-height: 1.2;
            font-weight: 600;
        }
    }

    ul {
        li {
            font-size: 15px;
            padding: 10px 0;
        }
    }
}