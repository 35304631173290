// import fonts here if needed
@import "fonts/AllFonts.scss";
// import fonts here if needed

// Import variables here
@import "variables/AllVariables";
// Import variables here




*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
    text-decoration: none;
    // list-style: none;
}

body{
    counter-reset: section;
}

li.conatact-list-item::marker{
    color: black;
    font-size: 24px;
}
li.conatact-list-item-Active::marker{
    color: $base-purple;
    font-size: 24px;
}

.accordian-tabs-hover:hover{
    background-color: #eee;
    border-top: 4px solid $base-purple !important;
}

.accordian-tab-active{
    background-color: #eee;
    border-top: 4px solid $base-purple !important;
}

.teams-card-hover:hover{
    background-color: $base-purple;
    color: white;
    cursor: pointer;
    transition-duration: 0.3s;
}

.locations-card-hover:hover{
    color: $base-purple;
    cursor: pointer;
    transition-duration: 0.3s;
}


@import"./mixins/FlexMixin";

@import "./helpers/Helpers";

@import "./components/loader/Loader";
@import "./components/NavBarWeb/NavBarWeb";
@import "./components/Banner/Banner";
@import "./components/Home/HeaderSection";
@import "./components/Home/HomeServices";
@import "./components/Home/TransportationSection";
@import "./components/Home/Steps";
@import "./components/ServiceCard/ServiceCard";
@import "./components/Home/Stats";
@import "./components/Pricing/Pricing.scss";
@import "./components/Tracking/Tracking";
@import "./components/admin/SideBar";
@import "./components/admin/placeOrder/PlaceOrder";
@import "./components/Footer/Footer";
@import "./components/floater/Floater";


#special{
    a{
        color: white;
    }
}

.navbar-toggler{
    background: $base-orange;
}

ul.signUpDropDown{
    height: 80px;
    width: 150px;
    margin: 0;
    
    li{
        height: 50%;
        width: 100%;
        // border: 1px solid red;
        @include flex;
        justify-content: flex-start;
        padding-left: 20px;
        cursor: pointer;
        &:hover{
            background-color: rgba(209, 209, 209, 0.37);
        }
    }
}


// // for pc
// @media(min-width:1020px){

// }
// // for tab
// @media(min-width:760px) and (max-width:1020px){

// }
// // for mob
// @media(max-width:760px){

// }


.Toggler{
    cursor: default !important;
    &:hover{
        color:black !important;
    }
}