// // for pc
// @media(min-width:1020px){

// }
// // for tab
// @media(min-width:760px) and (max-width:1020px){

// }
// // for mob
// @media(max-width:760px){

// }

nav.sideBar {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 50px;
    z-index: 10000000000000000;

    span.navLink-admin {
        width: 100%;
        height: 50px;
        @include flex;
        justify-content: flex-start;
        padding-left: calc(10% - 3px);
        border-left: 3px solid #eaeaea;
        transition: 0.1s;

        span.myIcon {
            color: grey;
            margin-right: 10px;
        }
        span.MyText {
            color: grey;
        }

        &:hover {
            border-left: 3px solid $base-orange;
            background-color: $base-purple;
            cursor: pointer;
            span.myIcon {
                color: white;
            }
            span.MyText {
                color: white;
            }
        }
    }
    span.ActiveNav {
        border-left: 3px solid $base-orange;
        background-color: $base-purple;
        cursor: pointer;
        span.myIcon {
            color: white;
        }
        span.MyText {
            color: white;
        }
    }
}

.dropdown-menu.show {
    display: block;
    transform: translate3d(-93px, 40px, 0px) !important;
}
