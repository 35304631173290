// for pc
@media (min-width: 1020px) {
    section.statsSection {
        background: #1c2431;
        // height: 169px;
        height: auto;
        width: 100%;
        @include flex;

        div.statsSectionContainer {
            width: 80%;
            height: auto;
            @include flex;
            flex-wrap: wrap;
            // border: 2px solid red;

            div.statsBox {
                height: 169px;
                width: 33.33333%;
                @include flex;
                color: white;

                h2.statsh2 {
                    margin: 0;
                }

                span.statsSpan {
                    color: inherit;
                    font-weight: 500;
                    font-size: 14px;
                    display: inline-block;
                    max-width: 75px;
                    text-align: left;
                    line-height: 0.85rem;
                    margin-left: 5px;
                }
            }
        }
    }
}
// for tab
@media (min-width: 760px) and (max-width: 1020px) {
    section.statsSection {
        background: #1c2431;
        // height: 169px;
        height: auto;
        width: 100%;
        @include flex;

        div.statsSectionContainer {
            width: 80%;
            height: auto;
            @include flex;
            flex-wrap: wrap;
            // border: 2px solid red;

            div.statsBox {
                height: 169px;
                width: 33.33333%;
                @include flex;
                color: white;

                h2.statsh2 {
                    margin: 0;
                }

                span.statsSpan {
                    color: inherit;
                    font-weight: 500;
                    font-size: 14px;
                    display: inline-block;
                    max-width: 75px;
                    text-align: left;
                    line-height: 0.85rem;
                    margin-left: 5px;
                }
            }
        }
    }
}
// for mob
@media (max-width: 760px) {
    
section.statsSection {
    background: #1c2431;
    // height: 169px;
    height: auto;
    width: 100%;
    @include flex;

    div.statsSectionContainer {
        width: 80%;
        height: auto;
        @include flex;
        flex-wrap: wrap;
        // border: 2px solid red;

        div.statsBox {
            height: 169px;
            width: 100%;
            @include flex;
            color: white;


            h2.statsh2{
                margin: 0;
            }

            span.statsSpan {
                color: inherit;
                font-weight: 500;
                font-size: 14px;
                display: inline-block;
                max-width: 75px;
                text-align: left;
                line-height: 0.85rem;
                margin-left: 5px;
            }
        }
    }
}

}
